import _get from 'lodash/get';
import _has from 'lodash/has';
import _set from 'lodash/set';

import { translateKeys } from '@atc/bonnet-parameters';
import { createAction, createCachedSelector, createSelector, DuckSelector } from '@atc/modular-redux';

import { applyFilterGroups, filtersDuckCreator } from 'axl-ducks';

import {
    compareFilterChips,
    excludedFilters,
    formatChipLabel,
    getMileageLabel,
    getPriceLabel,
    getYearLabel,
    showHoverText,
} from '@/utilities/filterChipUtil';

import { srpDuckPreset } from '@/ducks/presets';

import srpSelectedFiltersInOrderDuck from './srpSelectedFiltersInOrderDuck';

const DEFAULT_EMPTY_OBJECT = {};
const DEFAULT_EMPTY_ARRAY = [];
const DEFAULT_EMPTY_STRING = '';

// derive a filter's metadata
// Currently handles filter names with |  but we need to update the filter name delimiter to something more unique
export class FilterName {
    constructor(filterName) {
        this.original = filterName;
        const [key, ...rest] = filterName.split('|');
        this.segments = [key, rest.join('|')];
        this.hasGroup = rest.length > 0;
        this.group = this.hasGroup && key;
        this.name = this.hasGroup ? this.segments[1] : key;
    }
}

// verify filter value is not empty string or empty array
const isNotEmptyValue = (value) => (typeof value === 'string' && value !== '') || (typeof value === 'object' && value.length > 0);

const getOptions = ({
    filters = {},
    filterGroups = {},
    placeholders = {},
    activeExpansion,
    lastTouched,
    prevFilterOptions = {},
}) => {
    // TODO remove after full migration to LSC
    const makeCodeKey = filters.makeCode ? 'makeCode' : 'makeCodeList';

    // build a new filterOptions data set from each filter data source
    const filterOptions = {
        filters: {
            ...filters,

            // update maxPrice with placeholder
            maxPrice: {
                ...filters.maxPrice,
                placeholder: placeholders.maxPrice,
            },

            // update minPrice with placeholder
            minPrice: {
                ...filters.minPrice,
                placeholder: placeholders.minPrice,
            },

            // update makeCodeList.collapsed to not collapse if it was the last touched filter
            [makeCodeKey]: {
                ...filters[makeCodeKey],
                collapsed: (lastTouched === makeCodeKey) ? false : _get(filters, `${makeCodeKey}.collapsed`, true),
            },

        },
        filterGroups,
    };

    if (lastTouched && activeExpansion && lastTouched === activeExpansion) {

        // get filter name metadata
        const lastTouchedFilter = new FilterName(lastTouched);

        // Determine the correct path to the active filter data in the prevFilterOptions and new filterOptions
        const currentFilterPath = lastTouchedFilter.hasGroup ? [lastTouchedFilter.group, lastTouchedFilter.original] : [lastTouchedFilter.original];
        const filterOptionsPath = lastTouchedFilter.hasGroup ? ['filterGroups', lastTouchedFilter.group, lastTouchedFilter.name] : ['filters', lastTouchedFilter.name];

        // Retrieve the data for the active filter in the prevFilterOptions and new filterOptions
        const currentActiveFilterData = _get(prevFilterOptions, currentFilterPath, {});

        // We want to set these values from the new search options response on top of the old one
        // so we destructure them from the new search options response, so we can reassign later
        const { optionsSubIndex, value } = _get(filterOptions, filterOptionsPath, {});

        // Current API response for base filters options returns zero counts and disables all inputs beside selected values
        // due to this we need to update the filter options response to ignore all the data it has received besides the updated optionsSubIndex
        // and re-use the existing state of the active filter's options sub index
        _set(filterOptions, filterOptionsPath, {
            ...currentActiveFilterData,
            // set new sub-index and value
            optionsSubIndex,
            value,
        });

    }

    // Apply the filterGroups to generate the options
    return applyFilterGroups(filterOptions);
};

const getValues = ({
    options,
    query,
}) => {
    const values = Object.keys(options).reduce((acc, optionKey) => {

        // If filterOption has a value key then we assume that it is not a multi-filter (trim, model, etc...) or an aggregation filter
        // we set the value if defined by the option response or from the request params
        const hasValue = _has(options, [optionKey, 'value']);

        if (hasValue) {

            let value = _get(options, [optionKey, 'value'], '');

            if (optionKey === 'makeCodeList') {
                value = options.makeCodeList.value.sort((a, b) => query.makeCodeList.indexOf(a) - query.makeCodeList.indexOf(b));
            }

            // to maintain the smallest state possible only populate values if they exist
            if (isNotEmptyValue(value)) {
                // Assign filter value
                acc[optionKey] = { value };

                // only add protected flag when it is true
                const isProtected = _get(options, [optionKey, 'protected'], false);
                if (isProtected) {
                    acc[optionKey].protected = true;
                }
            }
        } else {
            // iterate over each multi-filter and set the value directly from the option response
            Object.keys(options[optionKey]).forEach((multiFilterKey) => {
                const multiFilter = _get(options, [optionKey, multiFilterKey], {});
                if (multiFilter.name && multiFilter.value && isNotEmptyValue(multiFilter.value)) {
                    acc[multiFilter.name] = { value: multiFilter.value };
                }
            });
        }

        return acc;
    }, {});

    const filtersOptionsKeys = Object.keys(options);

    // These filter keys are multi-filters and should never be set from the query
    // TODO: BONNET - we should determine more dynamic way to handle this
    const ignoredKeys = [
        'modelCodeList',
        'modelCode',
        'seriesCodeList',
        'seriesCode',
        'trimCodeList',
        'trimCode',
        'packages',
    ];

    // Iterate over passed in query and set any filter values that have not been previously defined
    Object.entries(query).forEach(([key, value]) => {
        // ensure the key from the payload is actually a filter key
        if ((filtersOptionsKeys.includes(key)
            && !Object.prototype.hasOwnProperty.call(values, key)
            && !ignoredKeys.includes(key))
            || (key === 'experience' && !Object.prototype.hasOwnProperty.call(values, key))
        ) {
            values[key] = { value };
        }
    });

    return values;
};

const setOtherFilters = (filterValue, filterOptions, filterKey, acc) => {
    filterValue.forEach((key) => {
        if (!!filterOptions[filterKey] || !!(filterKey.indexOf('|') !== -1
            && filterOptions[filterKey.split('|', 1)]
            && filterOptions[filterKey.split('|', 1)][filterKey])) {
            const filterValueOptions = filterOptions[filterKey] || ((filterOptions[filterKey.split('|', 1)])[filterKey]);
            const filterValueOption = filterValueOptions.options && filterValueOptions.options.find(({ value }) => value === key);
            if (filterValueOption) {
                const filterLabel = filterValueOption.label;
                const label = formatChipLabel(filterLabel || key, filterKey);
                acc.push({
                    label,
                    title: showHoverText(filterLabel || key),
                    filterName: filterKey,
                    value: filterValueOption.value,
                });
            }
        }
    });
};

const setMileageFilter = (filterOptions, filterValue, filterKey, acc) => {
    const maxMileageLabel = getMileageLabel(filterOptions, filterValue, filterKey);
    if (maxMileageLabel) {
        acc.push({
            label: maxMileageLabel,
            filterName: filterKey,
            value: filterValue,
        });
    }
};

const setPriceFilter = (filterKey, acc, selectedFilterValues) => {
    if (['minPrice', 'maxPrice'].includes(filterKey)) {
        if (acc.filter((key) => key.filterName === 'minPrice').length === 0
            && acc.filter((key) => key.filterName === 'maxPrice').length === 0) {
            const label = getPriceLabel(selectedFilterValues);
            acc.push({
                label,
                filterName: filterKey,
                value: selectedFilterValues,
            });
        }
    }
};

const setYearFilter = (filterKey, filterValue, acc, selectedFilterValues) => {
    if (['startYear', 'endYear'].includes(filterKey)) {
        if (filterValue && parseInt(filterValue, 10) !== 0
            && (acc.filter((key) => key.filterName === 'startYear').length === 0 && acc.filter((key) => key.filterName === 'endYear').length === 0)) {
            const label = getYearLabel(selectedFilterValues);
            acc.push({
                label,
                filterName: filterKey,
                value: selectedFilterValues,
            });
        }
    }
};

const setFilters = (filterKey, filterValue, filterOptions, acc) => {
    if (!excludedFilters.includes(filterKey)) {
        if (Array.isArray(filterValue) && !['maxMileage', 'keywordPhrases', 'productTypes'].includes(filterKey)) {
            setOtherFilters(filterValue, filterOptions, filterKey, acc);
        } else if (['maxMileage'].includes(filterKey)) {
            setMileageFilter(filterOptions, filterValue, filterKey, acc);
        } else {
            const label = formatChipLabel(filterValue, filterKey);
            acc.push({
                label,
                title: showHoverText(filterValue),
                value: filterValue,
                filterName: filterKey,
            });
        }
    }
};

const srpFiltersDuck = filtersDuckCreator({
    ...srpDuckPreset,
    initialState: {
        visible: false,
        initialized: false,
        activeExpansions: ['location', 'vehicleExchange', 'yearRange', 'makeCode', 'priceRange', 'listingType',
            'homeServices', 'dealType'],
    },
}).extend({
    types: [
        'SET_SRP_FILTERS',
    ],
    reducer: (state, action, { types }) => {
        switch (action.type) {

            case types.SET_SRP_FILTERS: {
                const {
                    filters,
                    filterGroups,
                    placeholders = {},
                    query,
                } = action.payload;

                const {
                    activeExpansion,
                    lastTouched,
                    options: prevFilterOptions,
                } = state;

                const options = getOptions({
                    filters,
                    filterGroups,
                    placeholders,
                    activeExpansion,
                    lastTouched,
                    prevFilterOptions,
                });

                const values = getValues({
                    options,
                    query,
                });

                return {
                    ...state,
                    initialized: true,
                    options,
                    values,
                };
            }

            default:
                return state;
        }
    },
    creators: ({ types }) => ({
        // TODO: BONNET NEXT - What is going on here?
        show: () => { },
        // TODO: BONNET NEXT - What is going on here?
        hide: () => { },
        setSrpFilters: createAction(types.SET_SRP_FILTERS),
    }),
    selectors: () => ({
        hasFiltersOptions: new DuckSelector((selectors) => createSelector(
            selectors.getFiltersOptions,
            (options) => Object.keys(options).length > 0,
        )),
        getFiltersKeys: new DuckSelector((selectors) => createSelector(
            selectors.getFiltersOptions,
            (options) => Object.keys(options),
        )),
        getLastModified: new DuckSelector((selectors) => createSelector(
            selectors.getLocalState,
            (state) => state.lastTouched && state.lastTouched.split('|')[0]
        )),
        hasSingleSelection: new DuckSelector((selectors) => createSelector(
            selectors.getFiltersValues,
            (values = {}) => Object.entries(values).reduce((acc, [key, value]) => ({
                ...acc,
                [key]: [].concat(value).length === 1,
            }), {})
        )),
        hasMultipleSelections: new DuckSelector((selectors) => createSelector(
            selectors.getFiltersValues,
            (values = {}) => Object.entries(values).reduce((acc, [key, value]) => ({
                ...acc,
                [key]: [].concat(value).length > 1,
            }), {})
        )),
        isMultiple: new DuckSelector((selectors) => createSelector(
            selectors.getFiltersOptions,
            (options = {}) => Object.entries(options).reduce((acc, [key, value]) => ({
                ...acc,
                [key]: Object.prototype.hasOwnProperty.call(value, 'options'),
            }), {})
        )),
        getFilterValueLabels: new DuckSelector((selectors) => createSelector(
            selectors.getLabelsByValues,
            selectors.getValuesState,
            (filtersValueLabels, selectedFilters) => Object.keys(selectedFilters).reduce((acc, key) => {

                const selectedFilter = _get(selectedFilters, [key], false);
                if (selectedFilter) {
                    let label = '';
                    // Process selected filters with many values e.g. makeCodeList: ["CHEV", "FORD"]
                    if (Array.isArray(selectedFilter.value) && selectedFilter.value.length > 0) {
                        let valueLabel = {};
                        selectedFilter.value.forEach((val) => {
                            label = _get(filtersValueLabels, [key, val], false);
                            valueLabel = { ...valueLabel, [val]: label };
                        });
                        // Add if filter key not yet added
                        if (!acc[key] && valueLabel) {
                            acc[key] = valueLabel;
                        }

                    } else {
                        label = _get(filtersValueLabels, [key, selectedFilter.value], false);

                        // Add if filter key not yet added
                        if (!acc[key]) {
                            // minYear, maxYear are user entered values and won't be in search options
                            if (!label) {
                                acc[key] = selectedFilter.value;
                            } else {
                                acc[key] = { [selectedFilter.value]: label };
                            }
                        }
                    }
                }

                return acc;
            }, {})
        )),

        // get specific filter options and values
        getActiveExpansions: new DuckSelector((selectors) => (state) => _get(selectors.getDuckState(state), 'activeExpansions', DEFAULT_EMPTY_ARRAY)),
        isExpansionActive: new DuckSelector((selectors) => createCachedSelector(
            selectors.getActiveExpansions,
            (state, filterName) => filterName,
            (activeExpansions = [], filterName) => activeExpansions.includes(filterName)
        )((state, filterName) => filterName || 'default')),

        getFeatureFilterOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'featureCode', DEFAULT_EMPTY_ARRAY)),
        getFeatureFilterValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'featureCode', DEFAULT_EMPTY_ARRAY)),

        getSellerTypeOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'sellerType', DEFAULT_EMPTY_ARRAY)),
        getSellerTypeValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'sellerType', DEFAULT_EMPTY_ARRAY)),

        getListingTypeOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'listingType', DEFAULT_EMPTY_OBJECT)),
        getListingTypeValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'listingType', DEFAULT_EMPTY_ARRAY)),

        getLocationFilterOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'location', DEFAULT_EMPTY_OBJECT)),
        getLocationFilterValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'location', DEFAULT_EMPTY_STRING)),

        getMarketExtensionOption: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'marketExtension', DEFAULT_EMPTY_STRING)),
        getMarketExtensionValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'marketExtension', DEFAULT_EMPTY_STRING)),

        getMakeFilterOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'makeCode', DEFAULT_EMPTY_OBJECT)),
        getMakeFilterValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'makeCode', DEFAULT_EMPTY_ARRAY)),

        getModelFilterOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'modelCode', DEFAULT_EMPTY_OBJECT)),
        getModelFilterValues: new DuckSelector((selectors) => createSelector(
            selectors.getFiltersValues,
            (filtersValues) => {
                const modelValues = {};
                Object.entries(filtersValues).forEach(([key, value]) => {
                    if (key.includes('modelCode')) {
                        Object.assign(modelValues, { [key]: value });
                    }
                });
                return modelValues;
            }
        )),

        getSeriesFilterOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'seriesCode', DEFAULT_EMPTY_OBJECT)),
        getSeriesFilterValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'seriesCode', DEFAULT_EMPTY_ARRAY)),

        getTrimFilterOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'trimCode', DEFAULT_EMPTY_ARRAY)),
        getTrimFilterValues: new DuckSelector((selectors) => createSelector(
            selectors.getFiltersValues,
            (filtersValues) => {
                const trimValues = {};
                Object.entries(filtersValues).forEach(([key, value]) => {
                    if (key.includes('trimCode')) {
                        Object.assign(trimValues, { [key]: value });
                    }
                });
                return trimValues;
            }
        )),

        getPackageFilterOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'packages', DEFAULT_EMPTY_ARRAY)),
        getPackageFilterValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'packages', DEFAULT_EMPTY_ARRAY)),

        getFeaturesOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'featureCode', DEFAULT_EMPTY_ARRAY)),
        getFeaturesValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'featureCode', DEFAULT_EMPTY_ARRAY)),

        getPriceOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'priceRange', DEFAULT_EMPTY_OBJECT)),
        getMinPriceOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'minPrice', DEFAULT_EMPTY_OBJECT)),
        getMaxPriceOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'maxPrice', DEFAULT_EMPTY_OBJECT)),
        getMinPriceValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'minPrice', null)),
        getMaxPriceValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'maxPrice', null)),

        getSearchRadiusOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'searchRadius', DEFAULT_EMPTY_OBJECT)),
        getSelectedSearchRadius: new DuckSelector((selectors) => (state) => +_get(selectors.getFiltersValues(state), 'searchRadius', 0)),

        getZipOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'zip', DEFAULT_EMPTY_OBJECT)),
        getSelectedZipValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'zip', DEFAULT_EMPTY_STRING)),

        getYearOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'yearRange', DEFAULT_EMPTY_OBJECT)),
        getYearValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'yearRange', DEFAULT_EMPTY_STRING)),
        getYearEndOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'endYear', DEFAULT_EMPTY_OBJECT)),
        getYearEndValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'endYear', DEFAULT_EMPTY_STRING)),
        getYearStartOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'startYear', DEFAULT_EMPTY_OBJECT)),
        getYearStartValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'startYear', DEFAULT_EMPTY_STRING)),

        getMileageOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'mileage', DEFAULT_EMPTY_ARRAY)),
        getMileageValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'mileage', DEFAULT_EMPTY_STRING)),

        getDriveTypeOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'driveGroup', DEFAULT_EMPTY_ARRAY)),
        getDriveTypeValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'driveGroup', DEFAULT_EMPTY_ARRAY)),

        getVehicleHistoryOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'vehicleHistoryType', DEFAULT_EMPTY_ARRAY)),
        getVehicleHistoryValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'vehicleHistoryType', DEFAULT_EMPTY_ARRAY)),

        getHomeServicesOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'homeServices', DEFAULT_EMPTY_ARRAY)),
        getHomeServicesValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'homeServices', DEFAULT_EMPTY_ARRAY)),

        getPriceRatingOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'dealType', DEFAULT_EMPTY_ARRAY)),
        getPriceRatingValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'dealType', DEFAULT_EMPTY_ARRAY)),

        getBodyStyleOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'vehicleStyleCode', DEFAULT_EMPTY_OBJECT)),
        getBodyStyleValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'vehicleStyleCode', DEFAULT_EMPTY_STRING)),

        getBodyStyleSubtypeCodeOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'bodyStyleSubtypeCode', DEFAULT_EMPTY_ARRAY)),
        getBodyStyleSubtypeCodeValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'bodyStyleSubtypeCode', DEFAULT_EMPTY_ARRAY)),

        getTruckBedLengthOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'truckBedLength', DEFAULT_EMPTY_ARRAY)),
        getTruckBedLengthValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'truckBedLength', DEFAULT_EMPTY_ARRAY)),

        getSedanSizeSubtypeOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'sedanSubtypeCode', DEFAULT_EMPTY_ARRAY)),
        getSedanSizeSubtypeValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'sedanSubtypeCode', DEFAULT_EMPTY_ARRAY)),

        getSuvSizeSubtypeOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'suvSubtypeCode', DEFAULT_EMPTY_ARRAY)),
        getSuvSizeSubtypeValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'suvSubtypeCode', DEFAULT_EMPTY_ARRAY)),

        getVanSizeSubtypeOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'vanSubtypeCode', DEFAULT_EMPTY_ARRAY)),
        getVanSizeSubtypeValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'vanSubtypeCode', DEFAULT_EMPTY_ARRAY)),

        getTransmissionCodeOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'transmissionCode', DEFAULT_EMPTY_ARRAY)),
        getTransmissionCodeValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'transmissionCode', DEFAULT_EMPTY_ARRAY)),

        getEngineDisplacementOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'engineDisplacement', DEFAULT_EMPTY_ARRAY)),
        getEngineDisplacementValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'engineDisplacement', DEFAULT_EMPTY_ARRAY)),

        getEngineCodeOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'engineCode', DEFAULT_EMPTY_ARRAY)),
        getEngineCodeValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'engineCode', DEFAULT_EMPTY_ARRAY)),

        getExteriorColorOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'extColorSimple', DEFAULT_EMPTY_ARRAY)),
        getExteriorColorValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'extColorSimple', DEFAULT_EMPTY_ARRAY)),

        getInteriorColorOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'intColorSimple', DEFAULT_EMPTY_ARRAY)),
        getInteriorColorValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'intColorSimple', DEFAULT_EMPTY_ARRAY)),

        getFuelTypeOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'fuelTypeGroup', DEFAULT_EMPTY_ARRAY)),
        getFuelTypeValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'fuelTypeGroup', DEFAULT_EMPTY_ARRAY)),

        getFuelEconomyOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'mpgRange', DEFAULT_EMPTY_ARRAY)),
        getFuelEconomyValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'mpgRange', '0')),

        getDoorsOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'doorCode', DEFAULT_EMPTY_ARRAY)),
        getDoorsValue: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'doorCode', DEFAULT_EMPTY_ARRAY)),

        getElectricMileRangeOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'electricMileRange', DEFAULT_EMPTY_ARRAY)),
        getElectricMileRangeValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'electricMileRange', DEFAULT_EMPTY_ARRAY)),

        getVehicleExchangeOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'vehicleExchange', DEFAULT_EMPTY_ARRAY)),
        getVehicleExchangeValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'vehicleExchange', DEFAULT_EMPTY_ARRAY)),

        getVehicleUseTypeOptions: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersOptions(state), 'vehicleUseType', DEFAULT_EMPTY_ARRAY)),
        getVehicleUseTypeValues: new DuckSelector((selectors) => (state) => _get(selectors.getFiltersValues(state), 'vehicleUseType', DEFAULT_EMPTY_ARRAY)),

        getBuyOnlineFilterOptions: new DuckSelector((selectors) => createSelector(
            selectors.getHomeServicesOptions,
            (homeServicesOptions) => homeServicesOptions?.options?.find(({ value }) => value === 'BUY_ONLINE') || DEFAULT_EMPTY_ARRAY,
        )),
        isBuyOnlineSelected: new DuckSelector((selectors) => createSelector(
            selectors.getHomeServicesValues,
            (homeServicesValues) => homeServicesValues.includes('BUY_ONLINE'),
        )),
        // end get specific filter options and values

        getSelectedFilterChips: new DuckSelector((selectors) => createSelector(
            selectors.getFiltersValues,
            selectors.getFiltersOptions,
            (filterValues, filterOptions) => {
                const selectedFilterValues = translateKeys(filterValues, { target: 'lsc' });
                delete selectedFilterValues.experience; // Buy Online already shows unique filter chips. We do not want to show redundant experience chips
                return Object.entries(selectedFilterValues).reduce((acc, [filterKey, filterValue]) => {
                    setFilters(filterKey, filterValue, filterOptions, acc);
                    setPriceFilter(filterKey, acc, selectedFilterValues);
                    setYearFilter(filterKey, filterValue, acc, selectedFilterValues);
                    return acc;
                }, []);
            }
        )),

        getSelectedFilterChipsInOrder: new DuckSelector((selectors) => createSelector(
            selectors.getSelectedFilterChips,
            srpSelectedFiltersInOrderDuck.selectors.getFilterChipsInOrder,
            (selectedFilterChips, filterChipsInOrder) => {

                if (filterChipsInOrder.length < 1) {
                    return selectedFilterChips;
                }

                // find the difference between what was an active filter and what is now a selected filter
                const additionalFilters = selectedFilterChips.filter((selectedFilter) => !filterChipsInOrder.find((activeFilter) => compareFilterChips(selectedFilter, activeFilter)));

                const subtractedFilters = filterChipsInOrder.filter((activeFilter) => !selectedFilterChips.find((selectedFilter) => compareFilterChips(activeFilter, selectedFilter)));

                let orderedFilterValues = [...filterChipsInOrder];

                // clean removed filters
                if (subtractedFilters.length > 0) {
                    const isKeepFilter = (filter) => subtractedFilters.filter((item) => compareFilterChips(item, filter)).length === 0;
                    orderedFilterValues = filterChipsInOrder.filter(isKeepFilter);
                }

                // Always add most recent filter(s) first
                if (additionalFilters.length > 0) {
                    orderedFilterValues = [...additionalFilters, ...filterChipsInOrder];
                }

                return orderedFilterValues;
            }
        )),
    }),
});

export default srpFiltersDuck;
