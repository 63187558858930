import { fetchCompositeJSON, fetchJSON } from '@bonnet/next/fetch';

import importAuthUtilityBundle from '@/utilities/importAuthUtilityBundle';

import PriceDropAlertsFetcher from './vdp/PriceDropAlertsFetcher';

const emailNotSentError = 'We\'re sorry, we were unable to send your email due to a server error. Please try again later.';
const priceDropError = 'We sent your email, but due to a server error, we were unable to complete your request to receive price alerts. Please try again later.';
const multipleEmailError = 'We\'re sorry, we were unable to complete your request due to an unexpected server error. Please try again later';

const constructEmailRequestBody = ({ partnerName, modelYear, optIn, phoneNumber, date, time, listingId, timeZoneId, clickType, leadType, ...data }) => {
    const leadServiceRequestBody = {
        ...data,
        leadType: leadType ?? 'VDP_EMAIL_SELLER',
        contactType: 'FYC',
        partnerName: partnerName || 'ConsumerSite',
        ccFlag: 'true',
        promotionsFlag: optIn,
        sourceUrl: window?.location?.href,
        clicktype: clickType,
        ...(listingId && { carId: listingId }),
        ...(phoneNumber && { phoneNumber }), // if string is an empty, remove from request body
        ...(modelYear && { modelYear: modelYear.toString() }),
    };

    if (date !== undefined && time !== undefined && timeZoneId !== undefined) {
        leadServiceRequestBody.appointment = { date, time, timeZoneId };
    }

    if (!listingId) {
        leadServiceRequestBody.lnxCode = 'spotalpha';
        leadServiceRequestBody.originPage = 'FYC_ALPHA_SS_EMAIL_SELLER';
    }

    // have excess attributes in leadService body causes bad request error, deleting unnecessary values

    ['bookmarkTitle', 'includeWalletData', 'copySelf', 'vdpType',
        'isNDSCalc'].forEach((e) => delete leadServiceRequestBody[e]);

    return leadServiceRequestBody;

};

const emailOwner = (data) => fetchJSON('/cars-for-sale/svc/leads/email/owner', {
    body: data,
    method: 'POST',
});

const callEmailOwnerFetcherServices = async ({ optIn, ...rest } = {}) => {
    const useMyatcPriceAlertsApi = optIn || false;
    const emailOwnerRequestBody = constructEmailRequestBody({ optIn, ...rest });
    const services = { email: emailOwner(emailOwnerRequestBody) };

    const { getCognitoIdToken } = await importAuthUtilityBundle();
    const idtoken = await getCognitoIdToken().catch((_) => null);

    if (useMyatcPriceAlertsApi) {
        const priceDropAlertsData = {
            bookmarkTitle: rest.bookmarkTitle,
            emailAddress: rest.fromEmailAddress,
            listingId: rest.listingId,
            mobilePhone: rest.phoneNumber,
            ownerId: rest.ownerId,
            idtoken,
        };

        services.alert = PriceDropAlertsFetcher(priceDropAlertsData);
    }
    let compositeResponse = {};
    try {
        compositeResponse = await fetchCompositeJSON(services) || {};

        if (useMyatcPriceAlertsApi && compositeResponse.alert?.responseCode !== '200' && compositeResponse.email?.status !== 'SUCCESS') {
            compositeResponse.errorMessage = multipleEmailError;
            compositeResponse.logMessage = 'Email send and price alert error';
        } else if (useMyatcPriceAlertsApi && compositeResponse.alert?.responseCode !== '200') {
            compositeResponse.errorMessage = priceDropError;
            compositeResponse.logMessage = 'Price alert error';
        } else if (compositeResponse.email?.status !== 'SUCCESS') {
            compositeResponse.errorMessage = emailNotSentError;
            compositeResponse.logMessage = 'Email send error';
        }

    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error submitting email form data', error);
        compositeResponse.errorMessage = multipleEmailError;
        compositeResponse.logMessage = 'Caught JS error: ' + error;
    }
    return compositeResponse;
};

export default (data = {}) => (callEmailOwnerFetcherServices(data));
