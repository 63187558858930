import { useCallback } from 'react';

import { useStore } from 'react-redux';

import deepmerge from 'deepmerge';
import _isArray from 'lodash/isArray';
import _some from 'lodash/some';

import { translateKeys } from '@atc/bonnet-parameters';

import {
    srpFiltersDuck,
    srpMarketExtensionDuck,
    srpNewSearchDuck,
    srpPaginationDuck,
    srpSortDuck,
    srpSponsorshipDuck,
} from '@/ducks/srp';

export default function useSrpSearch() {

    const store = useStore();

    return {
        getSrpSearchParams: useCallback(() => {
            const state = store.getState();
            return [
                ...srpFiltersDuck.selectors.getFiltersKeys(state),
                'isNewSearch',
                'marketExtension',
                'sortBy',
                'numRecords',
                'firstRecord',
            ];
        }, [store]),
        getSrpSearchState: useCallback(({
            filtersValues = {},
            action = 'merge',
            target = 'cs',
        }) => {

            const state = store.getState();

            const sort = srpSortDuck.selectors.getDuckState(state);
            const pagination = srpPaginationDuck.selectors.getDuckState(state);
            const marketExtension = srpMarketExtensionDuck.selectors.getDuckState(state);
            const isNewSearch = srpNewSearchDuck.selectors.getDuckState(state);
            const isSponsorship = srpSponsorshipDuck.selectors.getDuckState(state);
            const fuelTypeOptions = srpFiltersDuck.selectors.getFuelTypeOptions(state);
            const lastModifiedOption = srpFiltersDuck.selectors.getLastModified(state);

            const { numRecords, firstRecord } = pagination;

            const requestQueryValues = translateKeys(srpFiltersDuck.selectors.getRequestValues(state), { target });
            requestQueryValues.firstRecord = firstRecord;
            requestQueryValues.numRecords = numRecords;
            requestQueryValues.isNewSearch = isNewSearch;

            let selectedFilters;
            switch (action) {
                case 'merge':
                    selectedFilters = deepmerge(requestQueryValues, filtersValues, {
                        // dedupe arrays on merges
                        arrayMerge: (destination, source) => {
                            const array = destination.concat(source);
                            return Array.from(new Set(array));
                        },
                        /* customMerge: (key) => {
                           const overrideFilters = ['maxPrice', 'minPrice', 'endYear', 'startYear'];
                           //TODO ensure above filters never set an array value if supplied as one
                        },*/
                    });
                    break;
                case 'replace':
                    selectedFilters = filtersValues;
                    break;
                default:
            }

            const search = {
                // merge in selected filter set
                ...selectedFilters,
                marketExtension: marketExtension.value,
                sortBy: sort,
            };

            const mpgRange = 'mpgRange';
            const mileage = 'mileage';

            // TODO: BONNET - Looks like we are removing filter sets based off of what is currently selected.
            // Is this the best place for this?  Is it ok if this is always here?
            // TODO remove extra logic for CS parameters after full migration to LSC
            if (!search.vehicleStyleCodes && !search.vehicleStyleCode
                && !search.modelCodeList && !search.modelCode
                && search.bodyStyleSubtypeCodes) {
                delete search.bodyStyleSubtypeCodes;
            }

            if (!search.vehicleStyleCodes && !search.vehicleStyleCode
                && !search.modelCodeList && !search.modelCode
                && search.bodyStyleSubtypeCode) {
                delete search.bodyStyleSubtypeCode;
            }

            if (!search.vehicleStyleCodes && !search.vehicleStyleCode
                && !search.modelCodeList && !search.modelCode && search.truckBedLength) {
                delete search.truckBedLength;
            }

            if (!search.vehicleStyleCode?.includes('SEDAN')) { delete search.sedanSubtypeCode; }

            if (!search.vehicleStyleCode?.includes('SUVCROSS')) { delete search.suvSubtypeCode; }

            if (!search.vehicleStyleCode?.includes('VANMV')) { delete search.vanSubtypeCode; }

            // SRP: `lsc` will return EVMileRange to display as default if fuel type only has electric or electric is selected. (Tesla for example)
            const hasOnlyElectric = Array.isArray(fuelTypeOptions.options)
                && fuelTypeOptions.options.length === 1
                && _some(fuelTypeOptions.options, { label: 'Electric', value: 'ELE' });
            const isSelectedElectric = Array.isArray(search?.fuelTypeGroup) && search?.fuelTypeGroup?.includes('ELE');
            const hasChangedModelCode = ['modelCode', 'modelCodeList'].includes(lastModifiedOption);
            const hasMakeCode = search.makeCodeList || search.makeCode;
            const hasModelCode = search.modelCodeList || search.modelCode;
            // if electric is not selected, remove filters dependent on electric (fuel type has only electric in exception)
            if ((!search.fuelTypeGroup || !isSelectedElectric) && !hasModelCode && (!hasMakeCode || !hasOnlyElectric || hasChangedModelCode) && search.electricMileRange) {
                delete search.electricMileRange;
            }

            // TODO: BONNET - what is the scenario where we have to remove this?
            if (isSponsorship) {
                search.isSponsorship = isSponsorship;
            } else {
                // Removes the query parameter when false so that it doesn't show in the url at all
                delete search.isSponsorship;
            }

            // set startYear to null when the value {0 | `Any`} to remove it from URL
            if (search.startYear === '0') {
                search.startYear = null;
            }

            // set endYear to null when the value is {0 | `Any`} to remove it from URL
            if (search.endYear === '0') {
                search.endYear = null;
            }

            // set mpgRanges to null when the value {0 | `Any`} to remove it from URL
            if (search[mpgRange] === '0') {
                search[mpgRange] = null;
            }

            // set maxMileage to null when the value {0 | `Any`} to remove it from URL
            if (search[mileage] === '0') {
                search[mileage] = null;
            }

            Object.keys(search).forEach((key) => {
                if (_isArray(search[key])) search[key].sort((a, b) => a.localeCompare(b));
            });
            return translateKeys(search, { target });
        }, [store]),
    };
}
