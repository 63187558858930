const onGetDetailPageNameSrp = (showNoResults, isBuyOnline) => {
    if (isBuyOnline) {
        return 'srp_buy-online';
    }

    if (showNoResults) {
        return 'srp_no_results';
    }

    return 'srp';
};

export default function dataLayerTransform({ pageName, showNoResults, expiredVdp, isBuyOnline }) {
    let siteSection = '';
    let pageType = '';
    let detailPageName = '';

    switch (pageName) {
        case 'srp': {
            siteSection = 'find-your-car';
            pageType = showNoResults ? 'srp_no_results' : 'srp';
            detailPageName = onGetDetailPageNameSrp(showNoResults, isBuyOnline);
            break;
        }

        case 'vdp': {
            siteSection = 'find-your-car';
            pageType = expiredVdp ? 'vdp_exp' : 'vdp';
            detailPageName = expiredVdp ? 'vdp_exp' : 'vdp';
            break;
        }

        case 'sfp': {
            siteSection = 'find-your-car';
            pageType = 'asf';
            detailPageName = 'asf';
            break;
        }

        case 'fordlanding': {
            siteSection = 'find-your-car';
            pageType = 'slp_fba';
            detailPageName = 'slp_fba';
            break;
        }

        case 'bap': {
            siteSection = 'find-your-car';
            pageType = 'build-and-price';
            detailPageName = 'build-and-price';
            break;
        }

        case 'inv': {
            siteSection = 'find-your-car';
            pageType = 'inventory'; // TODO: Temp values until we know what to put here
            detailPageName = 'inventory'; // TODO: Temp values until we know what to put here
            break;
        }

        default:
    }

    return {
        siteSection,
        pageType,
        detailPageName,
    };
}
