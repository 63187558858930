import { formatNumber } from '@atc/string-fns';

// check if two filter chip items are equal
export const compareFilterChips = (primaryItem, secondaryItem) => {
    if (!primaryItem || !secondaryItem) {
        return primaryItem === secondaryItem;
    }

    if (primaryItem.filterName === 'mpgRanges') {
        return primaryItem.filterName === secondaryItem.filterName && primaryItem.value === secondaryItem.value;
    }

    return primaryItem.filterName === secondaryItem.filterName && primaryItem.label === secondaryItem.label;
};

// check if two filter chips array have the same items in the same order
export const compareFilterChipsList = (primaryList, secondaryList) => {
    if (!Array.isArray(primaryList) || !Array.isArray(secondaryList)) {
        return false;
    }

    if (primaryList.length !== secondaryList.length) {
        return false;
    }

    for (let i = 0; i < primaryList.length; i++) {
        if (!compareFilterChips(primaryList[i], secondaryList[i])) {
            return false;
        }
    }

    return true;
};

export const priceFormat = (labelValue) => labelValue && formatNumber({
    value: labelValue,
    style: 'currency',
    currency: 'USD',
    formatPretty: true,
});

export const formatChipLabel = (labelValue, filterName) => {
    // Match Buy Online Label to FilterName
    const updatedLabelValue = labelValue === 'Buy Online' ? 'Purchase Online' : labelValue;
    const chipLabel = (labelValue && labelValue.length >= 25) ? labelValue.substring(0, 22) + '...' : updatedLabelValue;

    switch (filterName) {
        case 'extColorsSimple':
        case 'extColorSimple': {
            return labelValue + ' Exterior';
        }
        case 'interiorColorsSimple':
        case 'intColorSimple': {
            return labelValue + ' Interior';
        }
        case 'keywordPhrases': {
            return `'${labelValue}'`;
        }
        default: {
            return chipLabel;
        }
    }
};

export const getPriceLabel = (filterValues) => {
    let label;
    const [minPrice, maxPrice] = [filterValues.minPrice, filterValues.maxPrice];

    if (minPrice && !maxPrice) {
        label = `Over ${priceFormat(minPrice)}`;
    } else if (!minPrice && maxPrice) {
        label = `Under ${priceFormat(maxPrice)}`;
    } else if (minPrice && maxPrice) {
        label = `${priceFormat(minPrice)} - ${priceFormat(maxPrice)}`;
    } else {
        label = '';
    }
    return label;
};

export const getYearLabel = (filterValues) => {
    let label;
    const [startYear, endYear] = [filterValues.startYear, filterValues.endYear];

    if (startYear && parseInt(startYear, 10) !== 0 && (!endYear || parseInt(endYear, 10) === 0)) {
        label = `Min ${startYear}`;
    } else if ((!startYear || parseInt(startYear, 10) === 0) && endYear && parseInt(endYear, 10) !== 0) {
        label = `Max ${endYear}`;
    } else if (startYear && parseInt(startYear, 10) !== 0 && endYear && parseInt(endYear, 10) !== 0) {
        label = `${startYear} - ${endYear}`;
    } else {
        label = '';
    }
    return label;
};

export const showHoverText = (labelValue) => {
    if (labelValue && (labelValue.length >= 25)) {
        return labelValue;
    }
    return '';
};

export const getMileageLabel = (filters, filterValue, filterKey) => {
    let maxMileageLabelValue = '';
    if (filters[filterKey]) {
        const filterValueOption = (filters[filterKey]).options.find(({ value }) => value === ((Array.isArray(filterValue) && filterValue[0]) || filterValue));
        if (filterValueOption) {
            maxMileageLabelValue = filterValueOption.label;
        }
    }
    return maxMileageLabelValue;
};

export const excludedFilters = [
    'location',
    'zip',
    'searchRadius',
    'marketExtension',
    'priceRange',
    'minPrice',
    'maxPrice',
    'startYear',
    'endYear',
    'packages',
    'modelCodeList',
    'modelCode',
    'productTypes',
    'seriesCodeList',
    'seriesCode',
    'trimCodeList',
    'trimCode',
    'vehicleExchange',
];

export default {
    compareFilterChips,
    compareFilterChipsList,
    priceFormat,
    formatChipLabel,
    getPriceLabel,
    getYearLabel,
    showHoverText,
    getMileageLabel,
    excludedFilters,
};
