import { fetchJSON } from '@bonnet/next/fetch';

export default async (data) => fetchJSON('/account-api/lightuser/vehicle', {
    body: {
        bookmarkTitle: data?.bookmarkTitle,
        email: data?.emailAddress,
        listingId: data?.listingId,
        mobilePhone: data?.mobilePhone,
        ownerId: data?.ownerId,
    },
    credentials: 'omit',
    headers: {
        idtoken: data.idtoken,
    },
    method: 'POST',
});
